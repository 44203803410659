import React, { Component } from 'react'

class ProjectBrowser extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount(){

  }

  select = (id) =>{
    this.props.selectProject(id)
  }

  render() {
    let projectsAllowed = this.props.projects.filter((project)=>{
      return !project.locked
    })
    let projectsSort = projectsAllowed.sort((a,b)=>{
      if(a.created > b.created){
        return -1
      }else if(a.created < b.created){
        return 1
      }else{
        return 0
      }
    })
    let projects = projectsSort.map((project,i)=>{
      let cityState = []
      if(project.city){
        cityState.push(project.city)
      }
      if(project.state){
        cityState.push(project.state)
      }
      let countryZip = []
      if(project.country){
        countryZip.push(project.country)
      }
      if(project.zip){
        countryZip.push(project.zip)
      }
      let location = cityState.join(', ') + ' ' + countryZip.join(' ')
      return (
        <button
        key={'project-'+i}
        className={(project.selected ? 'selected' : '')}
        onClick={(e)=>{this.select(project._id)}}>
          <div>
          {project.name}
          </div>
          <div>
          {location}&nbsp;
          </div>
        </button>
      )
    })
    return(
      <section className='project-select-list'>
      {projects}
      </section>
    )
  }
}

export default ProjectBrowser
