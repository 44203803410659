import React, { Component } from 'react'
import AuthPanel from './auth-panels'
import Layout from '../layout-alt'
import LoadingBox from '../loading-box'
import {
  Snackbar,
  Alert
} from '@mui/material'

class LoginWrapped extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      successMsg: '',
      errMsg: ''
    }
  }

  componentDidMount(){

  }

  handleSetLoading = (loading) => {
    this.setState({
      loading: loading
    })
  }

  handleCloseError = () => {
    this.setState({
      errMsg: ''
    })
  }

  handleCloseSuccess = () => {
    this.setState({
      successMsg: ''
    })
  }

  render() {
    return(
      <Layout>
      {this.state.loading &&
        <LoadingBox />
      }
      <div className='account-signin' style={{
        height: '100vh',
        maxHeight: '100%',
        padding: '2rem'
      }}>
      <AuthPanel
        handleSetLoading={this.handleSetLoading}
        defaultView='login'
        returnFromLogin={()=>{
          this.setState({
            successMsg: 'Login Successful'
          })
        }}
        setSuccessMessage={(message)=>{
          this.setState({
            successMsg: message
          })
        }}
        setErrorMessage={(message)=>{
          this.setState({
            errMsg: message
          })
        }}
      />
      </div>
      <Snackbar open={(this.state.errMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseError}>
        <Alert elevation={6} variant="filled" severity="error" onClose={this.handleCloseError}>
          {(this.state.errMsg ? this.state.errMsg : '')}
        </Alert>
      </Snackbar>
      <Snackbar open={(this.state.successMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseSuccess}>
        <Alert elevation={6} variant="filled" severity="success" onClose={this.handleCloseSuccess}>
          {(this.state.successMsg ? this.state.successMsg : '')}
        </Alert>
      </Snackbar>
      </Layout>
    )
  }
}

export default LoginWrapped
