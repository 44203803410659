import React, { Component } from 'react'
import Layout from '../layout-mobile'
import FavoriteItem from './favorite-item'
import {
  IconButton,
  Button,
  TextField,
  Snackbar,
  Alert,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CloseIcon from '@mui/icons-material/Close'
import LoadingBox from '../loading-box'
import ProjectBrowser from './project-browser'
import styled from 'styled-components'
import {
  getAllFavorites,
  deleteFavorite
} from '../../utils/favorite'
import {
  getUserInfo
} from '../../utils/user'
import {
  getAllProjects
} from '../../utils/project'
import {
  createDesign
} from '../../utils/design'
import { navigate } from '../../utils/misc'

class FavoritesDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sortType: 'chronoDesc',
      myFavorites: [],
      myProjects: [],
      anchorEl: null,
      sortEl: null,
      filterCategories: [],
      filterMostRecent: false,
      errMsg: '',
      successMsg: '',
      newProjectFormVisible: false,
      panel1: true,
      panel2: true,
      loading: true,
      pricing: null,
      showSubmitConfirm: false,
      userInfo: null,
      favoriteSelected: null,
      filterKeyword: '',
      deleteMode: false,
      deleteDialogOpen: false,
      showSort: false
    }
  }

  componentDidMount(){
    this.getFavorites()
    this.handleGetUserInfo()
    this.getProjects()
  }

  clickMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    })
  }

  getFavorites = () => {
    getAllFavorites()
    .then(function(data){
      this.setState({
        myFavorites: data,
        loading: false
      })
    }.bind(this))
    .catch(function(err){
      this.setError(err)
    }.bind(this))
  }

  setError = (err) => {
    if(err?.message){
      this.setState({
        errMsg: err.message,
        loading: false
      })
    }
  }

  handleGetUserInfo = (callbackFunction) => {
    this.setState({
      loading: true
    })
    getUserInfo()
    .then(info=>{
      this.setState({
        userInfo: info,
        loading: false
      }, function(){
        if(callbackFunction){
          callbackFunction()
        }
      })
    })
    .catch(err=>{
      this.setState({
        errMsg: err.toString(),
        loading: false
      })
    })
  }

  modifyFilterCategory = (e) => {
    let filterCategories = this.state.filterCategories
    if(e.target.checked){
      filterCategories.push(e.target.value)
    }else{
      let index = filterCategories.indexOf(e.target.value)
      if(index !== -1){
        filterCategories.splice(index, 1)
      }
    }
    this.setState({
      filterCategories: filterCategories
    })
  }

  handleSetLoading = (loading) => {
    this.setState({
      loading: loading
    })
  }

  handleCloseError = () => {
    this.setState({
      errMsg: ''
    })
  }

  handleCloseSuccess = () => {
    this.setState({
      successMsg: ''
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      downloadMenu: null
    })
  }

  handleAddToProjectSuccess = (projectsReturned) => {
    if(projectsReturned.length > 0){
      navigate('/project/'+ projectsReturned[0]._id)
    }
  }

  getProjects = () => {
    this.handleSetLoading(true)
    getAllProjects()
    .then(function(data){
      this.handleSetLoading(false)
      data = data.filter((object)=>{
        return !object.locked
      })
      this.setState({
        myProjects: data
      })
    }.bind(this))
    .catch(e => {
      this.handleSetLoading(false)
    })
  }

  updateSelectProject = (project, selected) => {
    console.log(project)
    let projects = [...this.state.myProjects]
    let foundProject = projects.find((item) => {
      return project._id === item._id
    })
    if(foundProject){
      console.log(foundProject)
      foundProject.selected = selected
      this.setState({
        myProjects: projects
      })
    }

  }

  toggleSearchVisible = () => {
    this.setState({
      showSearch: !this.state.showSearch
    })
  }

  handleFilterSearch = (e) => {
    this.setState({
      filterKeyword: e.target.value
    })
  }

  toggleDeleteMode = () => {
    this.setState({
      deleteMode: !this.state.deleteMode
    })
  }

  select = (favoriteID) => {
    console.log(favoriteID)
    let favorites = this.state.myFavorites
    let favoriteSelected = favorites.find((object) => {
      return object._id === favoriteID
    })
    favoriteSelected.selected = !favoriteSelected.selected
    console.log(favoriteSelected)
    this.setState({
      myFavorites: favorites,
      selectMenu: null
    })
  }

  selectOne = (favoriteID) => {
    let favorites = this.state.myFavorites
    let favoriteSelected = favorites.find((object) => {
      return object._id === favoriteID
    })
    this.setState({
      favoriteSelected: favoriteSelected,
      selectMenu: null
    })
  }

  selectProject = (projectId) => {
    let projects = this.state.myProjects
    projects.forEach((item, i) => {
      item.selected = false
    })
    let projectSelected = projects.find((object)=>{
      return object._id === projectId
    })
    projectSelected.selected = true
    this.setState({
      myProjects: projects
    })
  }

  handleDelete = async () => {
    this.handleSetLoading(true)
    await Promise.all(
      this.state.myFavorites.map(async (favorite) => {
        if(favorite.selected){
          await deleteFavorite(favorite._id)
        }
      })
    )
    this.handleSetLoading(false)
    this.getFavorites()
    this.handleDialogClose()
  }

  handleDialogClose = () =>{
    this.setState({
      deleteMode: false,
      deleteDialogOpen: false,
      favoriteSelected: null
    })
  }

  changeSortType = (sortType) => {
    let newSort = sortType
    if(newSort === this.state.sortType){
      if(newSort.includes('Desc')){
        newSort = newSort.replace('Desc','')
      }else{
        newSort = newSort + 'Desc'
      }
    }
    this.setState({
      sortType: newSort,
      sortEl: null
    })
  }

  addToProject = () => {
    this.handleSetLoading(true)
    let selectedProject = this.state.myProjects.find((project)=>{
      return project.selected
    })
    let newDesign = {...this.state.favoriteSelected}
    delete newDesign._id
    delete newDesign.created
    delete newDesign.updated
    newDesign.project = selectedProject._id
    createDesign(newDesign)
    .then((design)=>{
      navigate('/design/'+design._id)
    })
    .catch(err=>{
      console.log(err)
      this.setError(err)
    })
  }

  render() {
    let selected = 0
    let favorites = this.state.myFavorites
    let sortType = this.state.sortType
    let sortText = "Newest"
    let sortAlphaText = "Alphabetical Ascending"
    if(sortType === 'alpha') {
      favorites.sort((a, b) => a.name.localeCompare(b.name))
      sortText = "Alphabetical"
    }
    if(sortType === 'alphaDesc') {
      favorites.sort((a, b) => b.name.localeCompare(a.name))
      sortText = "Alphabetical"
      sortAlphaText = "Alphabetical Descending"
    }
    if(this.state.sortType === 'chronoUpdateDesc') {
      favorites.sort((a, b) => b.updated.localeCompare(a.updated))
      sortText = "Newest"
    }
    if(this.state.sortType === 'chronoUpdate') {
      favorites.sort((a, b) => a.updated.localeCompare(b.updated))
      sortText = "Oldest"
    }
    if(this.state.sortType === 'chronoDesc') {
      favorites.sort((a, b) => b.created.localeCompare(a.created))
      sortText = "Newest"
    }
    if(this.state.sortType === 'chrono') {
      favorites.sort((a, b) => a.created.localeCompare(b.created))
      sortText = "Oldest"
    }
    for(var i = 0; i< favorites.length; i++){
      if(favorites[i].selected){
        selected++
      }
    }
    let favoritesList = favorites.map((item, i)=>{
      let returnFavorite = true
      if(this.state.filterKeyword && returnFavorite){
        let filterKeyword = this.state.filterKeyword.toLowerCase()
        if(!item.name.toLowerCase().includes(filterKeyword)){
          returnFavorite = false
        }
      }
      if(returnFavorite){
        return(<FavoriteItem 
            design={item} key={i}
            handleSelect={this.select}
            handleSelectOne={this.selectOne}
            deleteMode={this.state.deleteMode}
            handleSetLoading={this.handleSetLoading}
            filterMostRecent={this.state.filterMostRecent}
            handleGetFavorites={this.getFavorites}
            handleSetError={this.setError}
          />)
      }else{
        return(null)
      }
    })
    if(favoritesList.length === 0){
      favoritesList = <p>No favorites found.</p>
    }
    let selectedProject = this.state.myProjects.find((project)=>{
      return project.selected
    })
    let toolFunctions = <>
    {this.state.deleteMode &&
    <>
    <strong>Select products to remove.</strong>
    <Button variant="standard" onClick={()=>{
      this.setState({
        deleteMode: false
      })
    }}>Cancel</Button>
    <Button variant="contained" disabled={!selected}
          onClick={()=>{
            this.setState({
              deleteDialogOpen: true
            })
          }}>Delete Selected</Button>
    </>
    }
    {!this.state.deleteMode &&
    <Button variant="contained" onClick={()=>{
      this.setState({
        deleteMode: true
      })
    }}>Delete Favorites</Button>
    }
    </>
    return(
      <Layout titleText="Favorites" showMenu={true} path={this.props.path} toolFunctions={toolFunctions}>
      {this.state.loading &&
        <LoadingBox />
      }
      <SearchEditAddSection>
          <IconButton onClick={(e)=>{
            this.setState({
              sortEl: e.currentTarget
            })
          }}>
            {sortText}
            {this.state.sortType.includes('Desc') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={this.state.sortEl}
            open={this.state.sortEl ? true : false}
            onClose={()=>{
              this.setState({sortEl: null})
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem className={['chrono','chronoDesc'].includes(this.state.sortType) ? 'active' : ''} 
            onClick={(e)=>{this.changeSortType('chronoDesc')}}>
            &nbsp;Chronological Created {(this.state.sortType === 'chrono') ? 'Ascending' : 'Descending'}
            </MenuItem>
            <MenuItem className={['chronoUpdated','chronoUpdatedDesc'].includes(this.state.sortType) ? 'active' : ''}  
            onClick={(e)=>{this.changeSortType('chronoUpdatedDesc')}}>
            &nbsp;Chronological Edited {(this.state.sortType === 'chronoUpdated') ? 'Ascending' : 'Descending'}
            </MenuItem>
            <MenuItem className={['alpha','alphaDesc'].includes(this.state.sortType) ? 'active' : ''}  
            onClick={(e)=>{this.changeSortType('alpha')}}>
            &nbsp;{sortAlphaText}
            </MenuItem>
          </Menu>
          {this.state.showSearch &&
            <form className='search-edit-create-form'>
            <TextField variant="filled"
            placeholder="Search favorites by name"
            value={this.state.filterKeyword}
            onChange={this.handleFilterSearch} />
            </form>
          }
      </SearchEditAddSection>
      <section className="favorites-list">
        {favoritesList}
      </section>
      <Dialog
        open={this.state.favoriteSelected ? true : false}
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           <IconButton onClick={()=>{
              this.setState({favoriteSelected: null})
            }}>
              <CloseIcon />
          </IconButton>
          <span>{'Select Project'}</span>
        </DialogTitle>
        <DialogContent>
        <ProjectBrowser
        projects={this.state.myProjects}
        selectProject={this.selectProject}
        handleSetLoading={this.handleSetLoading}
        />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={(selectedProject ? this.addToProject : null)}>
            Add to Project
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className="status-alert"
        open={this.state.deleteDialogOpen}
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           <IconButton onClick={()=>{
              this.setState({deleteDialogOpen: false})
            }}>
              <CloseIcon />
          </IconButton>
          <span>{'Remove From Favorites'}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove these products?
          </DialogContentText>
          <DialogContentText>
          Doing so will erase all data associated with this product. This action is permanent and cannot be undone. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.handleDelete}>
            Remove from Favorites
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={(this.state.errMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseError}>
        <Alert elevation={6} variant="filled" severity="error" onClose={this.handleCloseError}>
          {(this.state.errMsg ? this.state.errMsg : '')}
        </Alert>
      </Snackbar>
      <Snackbar open={(this.state.successMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseSuccess}>
        <Alert elevation={6} variant="filled" severity="success" onClose={this.handleCloseSuccess}>
          {(this.state.successMsg ? this.state.successMsg : '')}
        </Alert>
      </Snackbar>
      </Layout>
    )
  }
}

export default FavoritesDashboard


const SearchEditAddSection = styled.section`
display: flex;
justify-content: flex-end;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
margin-top: 10px;
margin-bottom: 10px;
gap: 10px;
form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
  .MuiTextField-root{
    width: calc(100%);
    line-height: 3rem;
    div:before {
      border-bottom: none;
    }
    input {
      border-bottom: none;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
button {
  height: 3rem;
  line-height: 3rem;
  display: flex;
  gap: 5px;
  flex-direction: row;
  background-color: transparent;
}
.edit-button {
  width: 100px;
  background-color: #C8C8C8;
  color: #000;
}
.new-project-button {
  width: 40%;
}
`
