import axios from "axios"
import { Auth } from "aws-amplify"
import { getUserAgent } from "./misc"
const restPath = process.env.GATSBY_APIURL

export const getAllProjects = (archived) => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function (session) {
          axios
            .get(
              restPath +
                "/project" +
                (archived ? "/archived" : "") +
                "?t=" +
                new Date().toString(),
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: session.idToken.jwtToken,
                },
              }
            )
            .then(function (projects) {
              if (projects.data) {
                if (projects.data.errorType === "Error") {
                  reject(new Error(projects.data.errorMessage))
                }
              } else {
                reject(new Error("no projects returned"))
              }
              resolve(projects.data)
            })
            .catch(function (err) {
              console.log(err)
              reject(err)
            })
        })
        .catch(function (err) {
          console.log(err)
          reject(err)
        })
    } catch (err) {
      console.log(err)
      reject(err)
    }
  })
}

export const getProject = projectId => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function (session) {
          axios
            .get(restPath + "/project/" + projectId, {
              headers: {
                "Content-Type": "application/json",
                Authorization: session.idToken.jwtToken,
              },
            })
            .then(function (project) {
              if (project.data) {
                if (project.data.errorType === "Error") {
                  reject(new Error(project.data.errorMessage))
                }
              } else {
                reject(new Error("no design returned"))
              }
              resolve(project.data)
            })
            .catch(function (err) {
              console.log(err)
              reject(err)
            })
        })
        .catch(function (err) {
          console.log(err)
          reject(err)
        })
    } catch (err) {
      console.log(err)
      reject(err)
    }
  })
}

export const createProject = project => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function (session) {
          project.agentString = getUserAgent()
          axios
            .post(restPath + "/project", project, {
              headers: {
                "Content-Type": "application/json",
                Authorization: session.idToken.jwtToken,
              },
            })
            .then(function (project) {
              if (project.data) {
                if (project.data.errorMessage) {
                  let dataError = new Error(project.data.errorMessage)
                  reject(dataError)
                } else {
                  resolve(project.data)
                }
              } else {
                let dataError = new Error("Malformed Response")
                reject(dataError)
              }
            })
            .catch(function (err) {
              reject(err)
            })
        })
        .catch(function (err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const updateProject = project => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function (session) {
          project.agentString = getUserAgent()
          axios
            .post(restPath + "/project/" + project._id, project, {
              headers: {
                "Content-Type": "application/json",
                Authorization: session.idToken.jwtToken,
              },
            })
            .then(function (project) {
              if (project.data) {
                if (project.data.errorMessage) {
                  reject(project.data.errorMessage)
                } else {
                  resolve(project.data)
                }
              } else {
                let dataError = new Error("Malformed Response")
                reject(dataError)
              }
            })
            .catch(function (err) {
              reject(err)
            })
        })
        .catch(function (err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const deleteProject = projectId => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function (session) {
          axios
            .delete(restPath + "/project/" + projectId, {
              headers: {
                "Content-Type": "application/json",
                Authorization: session.idToken.jwtToken,
              },
            })
            .then(function (project) {
              if (project.data) {
                if (project.data.errorMessage) {
                  let dataError = new Error(project.data.errorMessage)
                  reject(dataError)
                } else {
                  resolve(project.data)
                }
              } else {
                let dataError = new Error("Malformed Response")
                reject(dataError)
              }
            })
            .catch(function (err) {
              reject(err)
            })
        })
        .catch(function (err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const deleteProjectMultiple = projectIds => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function (session) {
          axios
            .post(restPath + "/project-delete-multi", projectIds, {
              headers: {
                "Content-Type": "application/json",
                Authorization: session.idToken.jwtToken,
              },
            })
            .then(function (project) {
              if (project.data) {
                if (project.data.errorMessage) {
                  reject(project.data.errorMessage)
                } else {
                  resolve(project.data)
                }
              } else {
                let dataError = new Error("Malformed Response")
                reject(dataError)
              }
            })
            .catch(function (err) {
              reject(err)
            })
        })
        .catch(function (err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const duplicateProject = project => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function (session) {
          project.agentString = getUserAgent()
          axios
            .post(restPath + "/project", project, {
              headers: {
                "Content-Type": "application/json",
                Authorization: session.idToken.jwtToken,
              },
            })
            .then(function (project) {
              if (project.data) {
                if (project.data.errorMessage) {
                  let dataError = new Error(project.data.errorMessage)
                  reject(dataError)
                } else {
                  resolve(project.data)
                }
              } else {
                let dataError = new Error("Malformed Response")
                reject(dataError)
              }
            })
            .catch(function (err) {
              reject(err)
            })
        })
        .catch(function (err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}
