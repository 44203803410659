import React from 'react'
import Favorites from '../components/Favorites'
import PrivateRoute from '../components/private-route'

const Dashboard = ({data, pageContext}) => {
  return(
    <PrivateRoute path="/favorites" component={Favorites} fsOnly='false' />
  )
}

export default Dashboard
