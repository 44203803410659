import axios from 'axios'
import { Auth } from 'aws-amplify'
import { getUserAgent } from './misc'
const restPath = process.env.GATSBY_APIURL

export const getDesign = designId => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .get(restPath + '/design/' + designId,
            {headers: {'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken }})
            .then(function(design) {
              if(design.data){
                if(design.data.errorType === 'Error'){
                  reject(new Error(design.data.errorMessage))
                }
              }else{
                reject(new Error('no design returned'))
              }
              resolve(design.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const createDesign = (design) => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          design.agentString = getUserAgent()
          axios
            .post(restPath + '/design', design,
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(design) {
              if(design.data){
                if(design.data.errorMessage){
                  let dataError = new Error(design.data.errorMessage)
                  reject(dataError)
                }else{
                  resolve(design.data)
                }
              }else{
                let dataError = new Error('Malformed Response')
                reject(dataError)
              }
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const updateDesign = design => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          design.agentString = getUserAgent()
          axios
            .post(restPath + '/design/'+design._id, design,
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(design) {
              if(design.data){
                if(design.data.errorMessage){
                  let dataError = new Error(design.data.errorMessage)
                  reject(dataError)
                }else{
                  resolve(design.data)
                }
              }else{
                let dataError = new Error('Malformed Response')
                reject(dataError)
              }
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}


export const deleteDesign = designId => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .delete(restPath + '/design/'+designId,
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(design) {
              if(design.data){
                if(design.data.errorMessage){
                  let dataError = new Error(design.data.errorMessage)
                  reject(dataError)
                }else{
                  resolve(design.data)
                }
              }else{
                let dataError = new Error('Malformed Response')
                reject(dataError)
              }
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const getDistinctCustomText = () => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .get(restPath + '/design-custom-text/',
            {headers: {'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken }})
            .then(function(design) {
              if(design.data){
                if(design.data.errorType === 'Error'){
                  reject(new Error(design.data.errorMessage))
                }
              }else{
                reject(new Error('no design returned'))
              }
              resolve(design.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}
