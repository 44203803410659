import React, { Component } from 'react'
import { deleteFavorite } from '../../utils/favorite'

class FavoriteItem extends Component {
  constructor(props) {
    super(props)
    this.designItem = React.createRef()
    this.state = {
      anchorEl: '',
      deleteDialogOpen: false,
      designName: this.props.design.name,
      showOnBoard: this.props.onBoardVisible2,
      projectBrowserVisible: false,
      quantity: 1
    }
  }

  componentDidMount(){
    this.setState({
      showOnBoard: this.props.onBoardVisible2
    })
  }

  componentDidUpdate(){
  }

  clickMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleDelete = () => {
    this.props.handleSetLoading(true)
    deleteFavorite(this.props.design._id)
    .then(function(data){
      this.setState({
        deleteDialogOpen: false,
        anchorEl: null
      },function(){
        this.props.handleGetFavorites()
      })
    }.bind(this))
    .catch(function(err){
      this.setState({
        deleteDialogOpen: false,
        anchorEl: null
      },function(){
        this.props.handleSetError(err)
      })
    }.bind(this))
  }

  handleDialogClose = () => {
    this.setState({
      deleteDialogOpen: false,
      anchorEl: null
    })
  }

  handleUpdateQuantity = (quantity) => {
    this.setState({
      quantity: quantity
    })
  }

  handleToggleSelected = (e) => {
    this.props.handleSelect(this.props.design._id, e)
  }

  render() {
    let design = this.props.design
    let imageSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/+9fPQAJewN8/jmw9AAAAABJRU5ErkJggg==';
    let titleText = "Options"

    if(design.imagePath){
      imageSrc = process.env.GATSBY_IMAGE_URL + design.imagePath + '?v=' + new Date(design.updated).getTime();
    }
    let timeDifference = new Date().getTime() - new Date(design.updated).getTime()
    let daysDifference = parseInt(timeDifference / (1000 * 3600 * 24))
    //Check filter values
    if(this.props.filterMostRecent){
      if(daysDifference >= 1){
        return (null)
      }
    }

    return(
      <div className={(this.props.design.selected ? 'selected' : '')}>
      <button onClick={(e)=>{
        if(!this.props.deleteMode){
          this.props.handleSelectOne(this.props.design._id)
        }else{
          this.handleToggleSelected(e)
        }
      }} aria-label={design.name}></button>
        <img src={imageSrc} alt={titleText} />
        <div>{design.name}</div>
      
      </div>
    )
  }
}

export default FavoriteItem
